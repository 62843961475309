import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../store/App/App.actions";
import { Button } from "../Button";
import { Container, TextField, Chip, Grid, Box } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CustomModal } from "../CustomModal";
import axios from "../../Engine/Infra/CustomAxios";
import { parseISO, parsePTBR } from "../../Shared/dateUtils";

export const ProjectReleaseDateModal = ({
  projects,
  open,
  onClose,
  handleTable,
}) => {
  const dispatch = useDispatch();
  const [activeProjects, setActiveProjects] = useState([]);
  const [projectSelectionError, setProjectSelectionError] = useState("");
  const [recordsReleaseDateError, setRecordsReleaseDateError] = useState("");
  const [form, setForm] = useState({ projects: [], recordsReleaseDate: "" });

  useEffect(() => {
    setActiveProjects(
      projects
        .filter((project) => project.status === "ACTIVE")
        .sort((a, b) => -b.name.localeCompare(a.name))
    );
    // eslint-disable-next-line
  }, []);

  const handleForm = async () => {
    if (!validateForm()) return false;

    const data = {
      projectIds: form.projects.map((project) => project.id),
      recordsReleaseDate: form.recordsReleaseDate,
    };

    dispatch(setLoading(true));
    axios
      .put("/projects/records-release-dates", data)
      .then((_) => {
        handleTable("CHANGE-RELEASE-DATE", data);
        onClose();
      })
      .catch((error) => {
        sendError(error?.response?.data?.message || "Erro");
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const validateForm = () => {
    let result = true;

    if (form.projects.length === 0) {
      setProjectSelectionError("Campo obrigatório");
      result = false;
    }

    if (form.recordsReleaseDate) {
      const recordsReleaseAsDate = parseISO(form.recordsReleaseDate);
      const beginningDateError =
        form.projects
          .map((project) => project.beginningDate)
          .filter(
            (beginningDate) => parsePTBR(beginningDate) > recordsReleaseAsDate
          ).length > 0;
      if (beginningDateError) {
        result = false;
        sendError(
          "Alteração não realizada. Verifique a data de início do(s) projeto(s) selecionado(s)"
        );
      } else {
        const endingDateError =
          form.projects
            .filter((project) => !!project.endingDate)
            .map((project) => parseISO(project.endingDate))
            .filter((endingDate) => endingDate < recordsReleaseAsDate).length >
          0;
        if (endingDateError) {
          result = false;
          sendError(
            "Alteração não realizada. Verifique a data de término do(s) projeto(s) selecionado(s)"
          );
        }
      }
    } else {
      setRecordsReleaseDateError("Campo obrigatório");
      result = false;
    }

    return result;
  };

  const formatProject = (project) => {

    return `${project.name} (${project.recordsReleaseDate}) ${project.endingDate ? `(Data Fim: ${project.endingDate.substring(8, 10)}/${project.endingDate.substring(5, 7)}/${project.endingDate.substring(0, 4)})` : ''}`;
  };

  const handleInputProjects = (_, selectedProjects) => {
    setProjectSelectionError("");
    setForm({ ...form, projects: selectedProjects });
  };

  const handleInputRecordsReleaseDate = (e) => {
    setRecordsReleaseDateError("");
    setForm({ ...form, recordsReleaseDate: e.target.value });
  };

  const sendError = (errorMessage) => {
    dispatch(
      setAlert({
        message: errorMessage,
        type: "error",
        open: true,
      })
    );
  };

  return (
    <Container>
      <CustomModal
        title="Editar Data de Liberação"
        onClose={onClose}
        open={open}
      >
        <Box sx={{ width: "100%", height: "35vh", maxHeight: "40vh" }}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Autocomplete
                limitTags={2}
                multiple
                getOptionSelected={(option, value) => option.id === value.id}
                options={activeProjects}
                onChange={(item, selectedProjects) => {
                  handleInputProjects(item, selectedProjects);
                }}
                getOptionLabel={(option) => formatProject(option)}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    label="Projeto"
                    placeholder="Escolha o projeto"
                    error={!!projectSelectionError}
                    helperText={projectSelectionError}
                    {...params}
                  />
                )}
                renderTags={(value, getTagProps) => {
                  return value.map((option, index) => (
                    <Chip
                      label={formatProject(option)}
                      {...getTagProps({ index })}
                      size="small"
                    />
                  ));
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className="dates-style"
                variant="outlined"
                onChange={handleInputRecordsReleaseDate}
                label="Data de Liberação"
                fullWidth
                error={!!recordsReleaseDateError}
                helperText={recordsReleaseDateError}
                id="date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { max: "5000-12-31" } }}
              />
            </Grid>
          </Grid>
        </Box>

        <div style={{ width: "100%", textAlign: "right" }}>
          <Button
            style={{ marginTop: "20px", marginBottom: "30px" }}
            onClick={handleForm}
          >
            Salvar data de liberação
          </Button>
        </div>
      </CustomModal>
    </Container>
  );
};
